<script>
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
// import DropZone from "@/components/widgets/dropZone";

import Axios from "../../../config/axios";
import store from "../../../state/store";

export default {
  props: ["app_settings"],
  data() {
    return {
      value: ["javascript"],
      images: [],
      date: null,
      show: false,
    };
  },
  components: {
    // DropZone,
  },
  setup() {
    let files = ref([]);
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      files.value.push(dropzoneFile.value);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      files.value.push(dropzoneFile.value);
    };
    watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );
    return {
      dropzoneFile,
      drop,
      selectedFile,
      v$: useVuelidate(),
      files,
    };
  },

  watch: {
    app_settings(value) {
      this.images = value.our_houses;
    },
  },
  methods: {
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },

    handleRemove(slide) {
      const test = confirm("Confirmer la suppression de cette image ?");

      if (test) {
        Axios.delete("/admin/app-settings/our-houses/" + slide.id);
        this.images = this.images.filter((image) => image.id != slide.id);
      }
    },

    async handleAdd(imgDataUrl) {
      const imageTitle = window.prompt("Entrez le titre de l'image !");

      if (!imageTitle) {
        return;
      }

      store.dispatch("setGlobalLoading", true);

      const image = await this.$upload_file(imgDataUrl, "houses");

      await Axios.post("/admin/app-settings/our-houses", {
        url: image,
        name: imageTitle,
      })
        .then(({ data }) => {
          this.images = data.data;
        })
        .finally(() => {
          store.dispatch("setGlobalLoading", false);
        });
    },

    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.show = false;
      this.handleAdd(imgDataUrl);
    },
  },
};
</script>

<template>
  <form action="javascript:void(0);">
    <div class="row g-2">
      <div class="card-body">
        <div class="vstack gap-2">
          <div class="row align-items-center">
            <div class="col-6">
              <h5 class="fs-14 mb-1">Liste nos maisons à crédit</h5>
              <p class="text-muted">
                Ajouter des images pour le slider de maisons.
              </p>
            </div>

            <div class="col-6 d-flex justify-content-end">
              <button class="btn btn-primary" type="button" @click="toggleShow">
                + Ajouter une image
              </button>
            </div>
          </div>
          <!-- <DropZone @drop.prevent="drop" @change="selectedFile" /> -->

          <div class="row gap-2">
            <div
              class="col-4 col-md-4"
              style="position: relative"
              v-for="slide in images"
              :key="slide.id"
            >
              <img class="w-100" :src="slide.image_url" alt="" />
              <div>{{ slide.name }}</div>
              <div
                style="
                  position: absolute;
                  right: 5px;
                  top: -10px;
                  background: gray;
                  height: 20px;
                  width: 20px;
                  text-align: center;
                  vertical-align: middle;
                  border-radius: 50%;
                  color: white;
                  cursor: pointer;
                "
                @click="handleRemove(slide)"
              >
                X
              </div>
            </div>
          </div>

          <my-upload
            field="img"
            @crop-success="cropSuccess"
            v-model="show"
            :width="1080"
            :height="1080"
            img-format="png"
            lang-type="fr"
          ></my-upload>

          <div
            class="border rounded"
            v-for="(file, index) of files"
            :key="index"
          >
            <div class="d-flex align-items-center p-2">
              <div class="flex-grow-1">
                <div class="pt-1">
                  <h5 class="fs-14 mb-1" data-dz-name="">
                    {{ file.name }}
                  </h5>
                  <p class="fs-13 text-muted mb-0" data-dz-size="">
                    <strong>{{ file.size / 1024 }}</strong> KB
                  </p>
                  <strong
                    class="error text-danger"
                    data-dz-errormessage=""
                  ></strong>
                </div>
              </div>
              <div class="flex-shrink-0 ms-3">
                <button
                  data-dz-remove=""
                  class="btn btn-sm btn-danger"
                  @click="deleteRecord"
                >
                  Effacer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
    <!-- <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end">
        <button type="submit" class="btn btn-primary">Modifier</button>
        <button type="button" class="btn btn-soft-success">Annuler</button>
      </div>
    </div> -->
  </form>
</template>
